import _ from 'lodash';
import { Promise } from 'bluebird';

import { cityService, mediaService, portfolioService } from '../../services/homii-services';

import {
    setIsLoadingAction,
    setCitiesAction,
    setSelectedCityAction,
    setCurrentCityAction,
} from './city.reducer';

export function getAllCities() {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));
        try {
            const fetchedCities = await cityService.getAllCities();
            const cities = await mediaService.generateCityImageUrls(fetchedCities);
            const citiesObject = {};

            _.forEach(cities, (fetchedCity) => {
                citiesObject[fetchedCity.id] = fetchedCity;
            });

            dispatch(setCitiesAction(citiesObject));
        } finally {
            dispatch(setIsLoadingAction(false));
        }
    };
}

export function getCityById(cityId) {
    return (dispatch) => {
        dispatch(setIsLoadingAction(true));
        return cityService
            .getCity(cityId)
            .then((city) => {
                dispatch(setCurrentCityAction(city));
            })
            .finally(() => {
                dispatch(setIsLoadingAction(false));
            });
    };
}

export function setSelectedCityByName(cityName) {
    return (dispatch, getState) => {
        const { cities } = getState().cityReducer;
        const selectedCity = _.find(cities, { name: cityName });
        if (selectedCity) {
            dispatch(setSelectedCityAction(selectedCity));
        } else {
            dispatch(setSelectedCityAction('Nearby'));
        }
    };
}

export function setSelectedCityById(cityId) {
    return (dispatch, getState) => {
        const { cities } = getState().cityReducer;
        const selectedCity = _.find(cities, { id: cityId });
        dispatch(setSelectedCityAction(selectedCity));
    };
}

export function setSelectedCityByClosestCoordinates(latitude, longitude, portfolioId) {
    return async (dispatch) => {
        const city = await cityService.getClosestCity(latitude, longitude, portfolioId);
        if (city) {
            await dispatch(setSelectedCityAction(city));
        } else {
            await dispatch(setSelectedCityByName('Durban'));
        }
    };
}

export function getAllCitiesByPortfolio(portfolioId) {
    return async (dispatch) => {
        dispatch(setIsLoadingAction(true));
        try {
            const cityIds = await portfolioService.getPortfolioCities(portfolioId);
            const cities = await Promise.map(cityIds, async (id) => {
                const city = await cityService.getCity(id);
                return city;
            });

            const cityObject = {};
            _.forEach(cities, (city) => {
                cityObject[city.id] = city;
            });
            dispatch(setCitiesAction(cityObject));
        } finally {
            dispatch(setIsLoadingAction(false));
        }
    };
}
